import React, { useMemo, useCallback } from 'react';
import { Trophy } from 'lucide-react';
import MiniWallet from './MiniWallet';
import './LeaderBoard.css';
import confetti from 'canvas-confetti';

// Memoized individual leaderboard item
const LeaderboardItem = React.memo(({ entry, index }) => {
  return (
    <div
      className="leaderboard-item"
      style={{
        animation: `fadeIn 200ms ${index * 100}ms forwards`,
      }}
    >
      <div
        className="rank-container"
        style={{
          animation: `scaleIn 500ms ${index * 100 + 200}ms forwards`,
        }}
      >
        <span className="rank-text">{entry.rank}</span>
      </div>

      <div className="user-info">
        <div
          className="avatar-container"
          style={{
            animation: `scaleIn 500ms ${index * 100 + 300}ms forwards`,
          }}
        >
          <div className="avatar">
            {entry.avatar ? (
              <img
                src={entry.avatar}
                alt={`${entry.username}'s avatar`}
                className="avatar-image"
              />
            ) : (
              <span>{entry.username.slice(0, 2).toUpperCase()}</span>
            )}
          </div>
        </div>

        <span
          className="username"
          style={{
            animation: `slideIn 500ms ${index * 100 + 400}ms forwards`,
          }}
        >
          {entry.username}
        </span>
      </div>

      <div className="score">
        <MiniWallet size={15} balance={entry.coins} />
      </div>
    </div>
  );
}, (prevProps, nextProps) => {
  // Custom comparison function for memo
  return (
    prevProps.entry.rank === nextProps.entry.rank &&
    prevProps.entry.username === nextProps.entry.username &&
    prevProps.entry.coins === nextProps.entry.coins &&
    prevProps.entry.avatar === nextProps.entry.avatar &&
    prevProps.index === nextProps.index
  );
});

// Memoized column component
const LeaderboardColumn = React.memo(({ players, startIndex }) => {
  return (
    <div style={{ overflow: 'hidden' }}>
      {players.map((player, index) => (
        <LeaderboardItem
          key={`${player.username}-${player.rank}`}
          entry={player}
          index={startIndex + index}
        />
      ))}
    </div>
  );
}, (prevProps, nextProps) => {
  // Deep comparison of players array
  if (prevProps.players.length !== nextProps.players.length) return false;
  
  return prevProps.players.every((player, index) => {
    const nextPlayer = nextProps.players[index];
    return (
      player.rank === nextPlayer.rank &&
      player.username === nextPlayer.username &&
      player.coins === nextPlayer.coins &&
      player.avatar === nextPlayer.avatar
    );
  });
});

const LeaderboardList = ({ players }) => {
  // Memoize the sorted players array
  const sortedPlayers = useMemo(() => {
    return [...players]
      .sort((a, b) => b.coins - a.coins)
      .map((player, index) => ({
        ...player,
        rank: index + 1,
      }));
  }, [players]);

  // Memoize the split between columns
  const { leftColumnPlayers, rightColumnPlayers } = useMemo(() => {
    const midpoint = Math.ceil(sortedPlayers.length / 2);
    return {
      leftColumnPlayers: sortedPlayers.slice(0, midpoint),
      rightColumnPlayers: sortedPlayers.slice(midpoint),
    };
  }, [sortedPlayers]);
  var star = confetti.shapeFromPath({
    path: 'M5,0 L6.18,3.09 L9.51,3.64 L7,5.91 L7.82,9.27 L5,7 L2.18,9.27 L3,5.91 L0.49,3.64 L3.82,3.09 Z'
  });
  // Run confetti only on first render
  React.useEffect(() => {
    confetti({
      particleCount: 10,
      spread: 100,
      useWorker: true,
      colors: ['FFE400', '00E096','53CBF3'],
      shapes: [star],
      scalar:3
    });
  }, []);

  return (
    <div>
      <div className="leaderboard-container">
        <LeaderboardColumn players={leftColumnPlayers} startIndex={0} />
        <LeaderboardColumn
          players={rightColumnPlayers}
          startIndex={leftColumnPlayers.length}
        />
      </div>
    </div>
  );
};

export default React.memo(LeaderboardList);