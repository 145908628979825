import React, { useEffect, useRef } from 'react';

const BackgroundMusic = ({ mode }) => {
  const audioRef = useRef(null);
  const fadeIntervalRef = useRef(null);
  const previousModeRef = useRef(null);

  useEffect(() => {
    // Initialize the Audio object once
    if (!audioRef.current) {
      audioRef.current = new Audio('/audio/theme.mp3'); // Adjust the path as needed
      audioRef.current.loop = true;
      audioRef.current.volume = 0; // Start with volume 0 for fade-in
    }

    const audio = audioRef.current;

    // Function to fade in the audio
    const fadeIn = (audio, duration = 2000) => {
      if (fadeIntervalRef.current) clearInterval(fadeIntervalRef.current);
      let volume = 0;
      const step = 0.05;
      const interval = duration / (1 / step); // Total duration divided by steps

      audio.volume = volume;
      audio.play().catch((error) => {
        console.error('Error playing audio:', error);
      });

      fadeIntervalRef.current = setInterval(() => {
        if (volume < 1) {
          volume += step;
          if (volume > 1) volume = 1;
          audio.volume = volume;
        } else {
          clearInterval(fadeIntervalRef.current);
          fadeIntervalRef.current = null;
        }
      }, interval);
    };

    // Function to fade out the audio
    const fadeOut = (audio, duration = 2000) => {
      if (fadeIntervalRef.current) clearInterval(fadeIntervalRef.current);
      let volume = audio.volume;
      const step = 0.05;
      const interval = duration / (volume / step); // Adjust interval based on current volume

      fadeIntervalRef.current = setInterval(() => {
        if (volume > 0) {
          volume -= step;
          if (volume < 0) volume = 0;
          audio.volume = volume;
        } else {
          clearInterval(fadeIntervalRef.current);
          fadeIntervalRef.current = null;
          audio.pause();
          audio.currentTime = 0;
        }
      }, interval);
    };

    const previousMode = previousModeRef.current;

    if (mode === 2 && previousMode !== 2) {
      // Mode changed to 2, start fade-in
      fadeIn(audio, 2000); // 2 seconds fade-in
    } else if (
      previousMode &&
      (previousMode >= 2 && previousMode <= 4) &&
      (mode < 2 || mode > 4)
    ) {
      // Mode changed from 2-4 to something else, start fade-out
      fadeOut(audio, 2000); // 2 seconds fade-out
    }

    // Update the previous mode
    previousModeRef.current = mode;

    // Cleanup on unmount
    return () => {
      if (fadeIntervalRef.current) {
        clearInterval(fadeIntervalRef.current);
      }
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    };
  }, [mode]);

  return null; // This component doesn't render anything
};

export default BackgroundMusic;