// src/components/QuestionComponent.js

import React, { useEffect, useState } from 'react';
import './QuestionComponent.css';
import { CircularProgressbar } from 'react-circular-progressbar';

import { faQuestionCircle, faInfoCircle, faTrophy, faLightbulb, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import ProgressBar from './ProgressBar';
import BackgroundMusic from './BackgroundMusic';
const l = ['A','B','C','D']
function QuestionComponent({ question, mode,soundOn=false }) {
    const [showContent, setShowContent] = useState(false);


    useEffect(() => {
        // Trigger fade-in when component mounts or mode changes to show question
        if (mode === 2 || mode === 3 || mode === 4) {
            setShowContent(true);
        } else {
            // Trigger fade-out when mode changes to hide question
            setShowContent(false);
        }
        if(mode==2)
        {     setText(question.question_text+'...is it.. A. ...'+ question.options[0]+'... B. ..'+ question.options[1]+'... C. ...'+ question.options[2]+'..or D. ...'+ question.options[3]);

        }else{
            setText('');
        }
        if(mode==4)
        {
            setText("The Answer is ... " + l[question.answerIndex]+'... ...' +question.options[question.answerIndex])
        }

    }, [mode]);
    const [fontSize, setFontSize] = useState(90); // initial max font size
    const [fontSizeE, setFontSizeE] = useState(90);
   const [text, setText] = useState('');
  
      useEffect(() => {
        if (!text.trim()) return;
        // Debounce: wait 300ms after the last change before speaking
     //   console.log(window.speechSynthesis.getVoices());
        const timer = setTimeout(() => {
          window.speechSynthesis.cancel();
          const utterance = new SpeechSynthesisUtterance(text);
          utterance.rate = 0.8;
          utterance.volume=0.7;
          //if(soundOn)
          //  window.speechSynthesis.speak(utterance);
        }, 300);
    
        return () => clearTimeout(timer);
      }, [text]);

    useEffect(() => {
      const maxFontSize = 50;
      const minFontSize = 30;
      const scale = 0.5;
      const newFontSize = Math.max(minFontSize, maxFontSize - question.question_text.length * scale);
      setFontSize(newFontSize);

    }, [question.question_text]);
    useEffect(() => {
        const maxFontSize = 50;
        const minFontSize = 30;
        const scale = 0.5;
        const newFontSizeE = Math.max(minFontSize, maxFontSize - question.explanation.length * scale);
        setFontSizeE(newFontSizeE);
      }, [question.explanation]);
    return (
        <>

        <div className="question-component">
            <div className="content-container">
                {(mode === 3 || mode === 2) && <div className={`question-text ${showContent ? 'fade-in' : 'fade-out'}`}>
        
    <h2 style={{ fontSize: `${fontSize}px` }}>{question.question_text}</h2>
{/* <h2>{question.question_text}</h2> */}
                </div>}
  
                {(mode === 4) && <div className={`question-text  ${showContent ? 'fade-in' : 'fade-out'}`}>
                    <div style={{alignItems:'center',textAlign:'center',color:'#ffdd55'}}>
                        <h2 style={{fontSize: `${fontSizeE}px`,color:'#ffdd55' }}>{'💡 ' + question.explanation}</h2>
                    </div>
                </div>}
                <div className="options">
                    {question.options.map((option, index) => (
                        <Option
                            key={index}
                            index={index}
                            optionLetter={String.fromCharCode(65 + index)}
                            optionText={option}
                            isCorrect={(mode === 3 || mode === 4) && index === question.answerIndex}
                            showContent={showContent}
                            mode={mode}
                        />
                    ))}
                </div>
            </div>

         

        </div></>
    );
}

function Option({ optionLetter, optionText, isCorrect, showContent, index,mode }) {
    return (
        <div
            className={`option ${isCorrect ? 'correct' :(mode==3 || mode==4) ?'wrong':''} ${showContent ? 'fade-in' : 'fade-out'}`}
            style={{ animationDelay: `${index * 0.3}s` }} // Adjust delay as needed
        >
            <div className="option-letter">
                <span>{optionLetter}</span>
            </div>
            <div className="option-text">
                <span>{optionText}</span>
            </div>
        </div>
    );
}

export default QuestionComponent;