import React, { useState, useEffect, useRef } from 'react';
import './ProgressBar.css'; // Import the CSS file

const ProgressBar = ({ d, mode }) => {
  const [progress, setProgress] = useState(0);
  const duration = d * 1000; // Convert seconds to milliseconds
  const updateInterval = 10; // Update every 10ms
  const totalUpdates = duration / updateInterval;
  const increment = 100 / totalUpdates;
  const intervalRef = useRef(null);

  // Clear existing interval and reset progress when mode changes to 2
  useEffect(() => {
    if (mode === 2) {
      // Clear any existing interval
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      
      // Reset progress
      setProgress(0);
      
      // Start a new interval
      intervalRef.current = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + increment;
          if (newProgress >= 100) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            return 100;
          }
          return newProgress;
        });
      }, updateInterval);
    }
    
    // Cleanup on unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [mode, increment, updateInterval]);

  // Initial setup (only runs once)
  useEffect(() => {
    // Start the interval timer if not already started by mode change
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + increment;
          if (newProgress >= 100) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            return 100;
          }
          return newProgress;
        });
      }, updateInterval);
    }

    // Cleanup on unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [increment, updateInterval]);

  return (
    <div className="progress-container" style={{ textAlign: 'center' }}>
      <progress value={progress} max="100"></progress>
    </div>
  );
};

export default ProgressBar;